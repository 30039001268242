.DropdownMenu_dropdownContainer__5bcIL {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  top: 4rem;
  z-index: 50;
  display: flex;
  height: 100vh;
  width: 100vw;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  flex-direction: column;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

@media (min-width: 640px) {
  .DropdownMenu_dropdownContainer__5bcIL {
    position: absolute;
  }
  .DropdownMenu_dropdownContainer__5bcIL {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .DropdownMenu_dropdownContainer__5bcIL {
    right: 0px;
  }
  .DropdownMenu_dropdownContainer__5bcIL {
    height: auto;
  }
  .DropdownMenu_dropdownContainer__5bcIL {
    width: auto;
  }
  .DropdownMenu_dropdownContainer__5bcIL {
    border-radius: 0.5rem;
  }
  .DropdownMenu_dropdownContainer__5bcIL {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .DropdownMenu_dropdownContainer__5bcIL {
    top: calc(100% + 16px);
    max-height: 70vh;
  }

  .DropdownMenu_dropdownContainer__5bcIL::before {
    position: absolute;
  }

  .DropdownMenu_dropdownContainer__5bcIL::before {
    top: -0.5rem;
  }

  .DropdownMenu_dropdownContainer__5bcIL::before {
    right: 0.875rem;
  }

  .DropdownMenu_dropdownContainer__5bcIL::before {
    border-bottom-width: 8px;
  }

  .DropdownMenu_dropdownContainer__5bcIL::before {
    border-right-width: 8px;
  }

  .DropdownMenu_dropdownContainer__5bcIL::before {
    border-left-width: 8px;
  }

  .DropdownMenu_dropdownContainer__5bcIL::before {
    border-style: solid;
  }

  .DropdownMenu_dropdownContainer__5bcIL::before {
    border-color: transparent;
  }

  .DropdownMenu_dropdownContainer__5bcIL::before {
    --tw-border-opacity: 1;
    border-top-color: rgb(255 255 255 / var(--tw-border-opacity));
  }

  .DropdownMenu_dropdownContainer__5bcIL::before {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity));
  }

  .DropdownMenu_dropdownContainer__5bcIL::before {
    filter: drop-shadow(0 -1px 1px rgba(0, 0, 0, 0.25));
    content: "";
  }
}

